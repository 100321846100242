import { Web3Provider } from '@ethersproject/providers'
import { InjectedConnector } from '@web3-react/injected-connector'
import { WalletConnectConnector } from '@web3-react/walletconnect-connector'
import { WalletLinkConnector } from '@web3-react/walletlink-connector'
// import { PortisConnector } from '@web3-react/portis-connector';
// import { FortmaticConnector } from './Fortmatic';
import { NetworkConnector } from './NetworkConnector'
import { ChainId } from '@uniswap/sdk-core'

const REACT_APP_ENV: 'development' | 'production' =
  (process.env.REACT_APP_ENV as 'development' | 'production') ?? 'development'

const REACT_APP_NETWORK_URL = {
  development: 'https://ethereum-sepolia.rpc.subquery.network/public',
  production: 'https://rpc.public.curie.radiumblock.co/http/ethereum'
}[REACT_APP_ENV]
// const FORMATIC_KEY = process.env.REACT_APP_FORTMATIC_KEY
// const PORTIS_ID = process.env.REACT_APP_PORTIS_ID

export const NETWORK_CHAIN_ID: number = {
  development: 11155111,
  production: 1
}[REACT_APP_ENV]

export const network = new NetworkConnector({
  urls: { [NETWORK_CHAIN_ID]: REACT_APP_NETWORK_URL }
})

let networkLibrary: Web3Provider | undefined
export function getNetworkLibrary(): Web3Provider {
  return (networkLibrary = networkLibrary ?? new Web3Provider(network.provider as any))
}

export const injected = new InjectedConnector({
  supportedChainIds: [
    ChainId.MAINNET,
    ChainId.ARBITRUM_ONE,
    ChainId.AVALANCHE,
    ChainId.BNB,
    ChainId.BNB_TESTNET,
    ChainId.OPTIMISM,
    ChainId.SEPOLIA
  ]
})

// mainnet only
export const walletconnect = new WalletConnectConnector({
  rpc: {
    // [ChainId.MAINNET]: 'https://ethereum-rpc.publicnode.com',
    [ChainId.SEPOLIA]: REACT_APP_NETWORK_URL
    // [ChainId.ARBITRUM_ONE]: 'https://arbitrum-one-rpc.publicnode.com',
    // [ChainId.AVALANCHE]: 'https://avax.meowrpc.com',
    // [ChainId.BNB]: 'https://bsc-rpc.publicnode.com',
    // [ChainId.BNB_TESTNET]: 'https://bsc-testnet.public.blastapi.io',
    // [ChainId.OPTIMISM]: 'https://op-pokt.nodies.app'
  },
  bridge: 'https://bridge.walletconnect.org',
  qrcode: true,
  pollingInterval: 15000
})

// mainnet only
// export const fortmatic = new FortmaticConnector({
//   apiKey: FORMATIC_KEY ?? '',
//   chainId: 1
// })

// mainnet only
// export const portis = new PortisConnector({
//   dAppId: PORTIS_ID ?? '',
//   networks: [1]
// })

// mainnet only
export const walletlink = new WalletLinkConnector({
  url: REACT_APP_NETWORK_URL,
  appName: 'Swap'
  // appLogoUrl: '',
})
