import { ChainId, Currency, Ether, Token, WETH9 } from '@uniswap/sdk-core'

export function wrappedCurrency(currency: Currency | undefined, chainId: ChainId | undefined): Token | undefined {
  return chainId && currency && Ether.onChain(chainId).equals(currency)
    ? WETH9[chainId]
    : currency instanceof Token
    ? currency
    : undefined
}

export function unwrappedToken(token: Token): Currency {
  if (token.equals(WETH9[token.chainId])) return Ether.onChain(token.chainId)
  return token
}
