import React, { useCallback, useEffect } from 'react'
import styled from 'styled-components'
import { ReactComponent as Close } from '../../assets/svg/x.svg'
// import { OVERLAY_READY } from '../../connectors/Fortmatic';
import { SUPPORTED_CHAINS } from '../../constants'
import usePrevious from '../../hooks/usePrevious'
import { ApplicationModal } from '../../state/application/actions'
import { useModalOpen, useNetworkModalToggle } from '../../state/application/hooks'

import Modal from '../Modal'
import Option from './Option'
import { useWeb3React } from '@web3-react/core'
import { ChainId } from '@uniswap/sdk-core'

const CloseIcon = styled.div`
  position: absolute;
  right: 1rem;
  top: 1rem;

  &:hover {
    cursor: pointer;
    opacity: 0.5;
  }
`

const CloseColor = styled(Close)`
  path {
    stroke: ${({ theme }) => theme.text4};
  }
`

const Wrapper = styled.div`
  ${({ theme }) => theme.flexColumnNoWrap}
  margin: 0;
  padding: 0;
  width: 100%;
`

const HeaderRow = styled.div`
  ${({ theme }) => theme.flexRowNoWrap};
  padding: 1rem 1rem;
  font-weight: 500;
  color: ${props => (props.color === 'blue' ? ({ theme }) => theme.primary1 : 'inherit')};
  ${({ theme }) => theme.mediaWidth.upToMedium`
    padding: 1rem;
  `};
`

const ContentWrapper = styled.div`
  background-color: ${({ theme }) => theme.bg2};
  padding: 2rem;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;

  ${({ theme }) => theme.mediaWidth.upToMedium`padding: 1rem`};
`

const UpperSection = styled.div`
  position: relative;

  h5 {
    margin: 0;
    margin-bottom: 0.5rem;
    font-size: 1rem;
    font-weight: 400;
  }

  h5:last-child {
    margin-bottom: 0px;
  }

  h4 {
    margin-top: 0;
    font-weight: 500;
  }
`

const OptionGrid = styled.div`
  display: grid;
  grid-gap: 10px;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    grid-template-columns: 1fr;
    grid-gap: 10px;
  `};
`

const HoverText = styled.div`
  :hover {
    cursor: pointer;
  }
`

export default function NetworkModal() {
  // important that these are destructed from the account-specific web3-react context
  const { active, chainId, connector } = useWeb3React()
  const networkModalOpen = useModalOpen(ApplicationModal.NETWORK)
  const toggleWalletModal = useNetworkModalToggle()

  const chainIdPrevious = usePrevious(chainId)
  useEffect(() => {
    if (networkModalOpen && chainId !== chainIdPrevious) {
      toggleWalletModal()
    }
  }, [active, networkModalOpen, chainId, chainIdPrevious, toggleWalletModal])

  const switchChain = useCallback(
    async (_chainId: ChainId) => {
      const provider = await connector?.getProvider()
      provider.request({
        method: 'wallet_switchEthereumChain',
        params: [
          {
            chainId: '0x' + _chainId.toString(16)
          }
        ]
      })
    },
    [connector]
  )

  // get wallets user can switch too, depending on device/browser
  function getOptions() {
    return Object.keys(SUPPORTED_CHAINS).map((key: string) => {
      const option = SUPPORTED_CHAINS[Number(key)]
      return (
        <Option
          onClick={() => {
            if (option.chainName !== "Solar") {
              connector && switchChain(option.chainId)
            } else {
              ///// do nothing for now (Solar Core 5.0)
            }
          }}
          id={`switch-chain-${option}`}
          key={option.chainName}
          active={chainId === option.chainId}
          header={option.chainName}
          subheader={null}
          icon={option.icon}
          disabled={option.chainName === "Solar"}
        />
      )
    })
  }

  function getModalContent() {
    return (
      <UpperSection>
        <CloseIcon onClick={toggleWalletModal}>
          <CloseColor />
        </CloseIcon>
        <HeaderRow>
          <HoverText>Select a network</HoverText>
        </HeaderRow>
        <ContentWrapper>
          <OptionGrid>{getOptions()}</OptionGrid>
        </ContentWrapper>
      </UpperSection>
    )
  }

  return (
    <Modal isOpen={networkModalOpen} onDismiss={toggleWalletModal} minHeight={false} maxHeight={90}>
      <Wrapper>{getModalContent()}</Wrapper>
    </Modal>
  )
}
