import { Currency, CurrencyAmount, V2_FACTORY_ADDRESSES } from '@uniswap/sdk-core'
import { Pair } from '@uniswap/v2-sdk'
import { useMemo } from 'react'
import { abi as IUniswapV2PairABI } from '@uniswap/v2-core/build/IUniswapV2Pair.json'
import { abi as IUniswapV2Factory } from '@uniswap/v2-core/build/UniswapV2Factory.json'
import { Interface } from '@ethersproject/abi'
import { Contract } from '@ethersproject/contracts'
import { useActiveWeb3React } from '../hooks'

import { useMultipleContractSingleData, useSingleContractMultipleData } from '../state/multicall/hooks'
import { wrappedCurrency } from '../utils/wrappedCurrency'
import { zeroAddress } from 'ethereumjs-util'
import { NETWORK_CHAIN_ID } from 'connectors'

const PAIR_INTERFACE = new Interface(IUniswapV2PairABI)
const FACTORY_INTERFACE = new Interface(IUniswapV2Factory)

export enum PairState {
  LOADING,
  NOT_EXISTS,
  EXISTS,
  INVALID
}

export function usePairs(currencies: [Currency | undefined, Currency | undefined][]): [PairState, Pair | null][] {
  const { chainId } = useActiveWeb3React()

  const tokens = useMemo(
    () =>
      currencies
        .map(([currencyA, currencyB]) => [wrappedCurrency(currencyA, chainId), wrappedCurrency(currencyB, chainId)])
        .filter(([currencyA, currencyB]) => currencyA && currencyB),
    [chainId, currencies]
  )

  // const pairAddresses = useMemo(
  //   () =>
  //     tokens.map(([tokenA, tokenB]) => {
  //       return tokenA && tokenB && !tokenA.equals(tokenB) ? Pair.getAddress(tokenA, tokenB) : undefined
  //     }),
  //   [tokens]
  // )
  const pairAddresses = useSingleContractMultipleData(
    new Contract(V2_FACTORY_ADDRESSES[chainId ?? NETWORK_CHAIN_ID], FACTORY_INTERFACE),
    'getPair',
    tokens.map(([tokenA, tokenB]) => [tokenA?.address, tokenB?.address])
  )
  // computePairAddress
  const results = useMultipleContractSingleData(
    pairAddresses.map(value => (value.result ? value.result[0] : zeroAddress())),
    PAIR_INTERFACE,
    'getReserves'
  )

  console.groupEnd()

  return useMemo(() => {
    return results.map((result, i) => {
      const { result: reserves, loading } = result
      const tokenA = tokens[i][0]
      const tokenB = tokens[i][1]

      if (loading) return [PairState.LOADING, null]
      if (!tokenA || !tokenB || tokenA.equals(tokenB)) return [PairState.INVALID, null]
      if (!reserves || Number(reserves.reserve0) === 0 || Number(reserves.reserve1) === 0)
        return [PairState.NOT_EXISTS, null]
      const { reserve0, reserve1 } = reserves
      const [token0, token1] = tokenA.sortsBefore(tokenB) ? [tokenA, tokenB] : [tokenB, tokenA]
      return [
        PairState.EXISTS,
        new Pair(
          CurrencyAmount.fromRawAmount(token0, reserve0.toString()),
          CurrencyAmount.fromRawAmount(token1, reserve1.toString())
        )
      ]
    })
  }, [results, tokens])
}

export function usePair(tokenA?: Currency, tokenB?: Currency): [PairState, Pair | null] {
  const pairs = usePairs([[tokenA, tokenB]])
  return pairs.length > 0 ? pairs[0] : [PairState.NOT_EXISTS, null]
}
