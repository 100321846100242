import { Currency, CurrencyAmount, Ether } from '@uniswap/sdk-core'
import { MIN_ETH } from '../constants'

/**
 * Given some token amount, return the max that can be spent of it
 * @param currencyAmount to return max of
 */
export function maxAmountSpend(currencyAmount?: CurrencyAmount<Currency>): CurrencyAmount<Currency> | undefined {
  if (!currencyAmount) return undefined
  if (currencyAmount.currency.equals(Ether.onChain(currencyAmount.currency.chainId))) {
    if (currencyAmount.greaterThan(MIN_ETH)) {
      return CurrencyAmount.fromRawAmount(Ether.onChain(currencyAmount.currency.chainId), MIN_ETH)
    } else {
      return CurrencyAmount.fromRawAmount(Ether.onChain(currencyAmount.currency.chainId), 0)
    }
  }
  return currencyAmount
}
